@import url("https://fonts.googleapis.com/css2?family=Anton&family=Kaushan+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baumans&family=Macondo+Swash+Caps&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  height: 130px;
  width: 100%;
  position: fixed;
  font-family: "Baumans", cursive;
  background-color: #000;
}
nav ul {
  float: center;
  text-align: center;
  margin-right: 20px;
  background-color: linear-gradient(to right, #ce9690, #3d86ab);
}
nav ul li {
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
}
/* .atrium{
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
} */
nav ul li a {
  color: white;
  font-size: 25px;
  font-weight: 500;
  padding: 7px 13px;
  border-radius: 3px;
  text-transform: uppercase;
}
.atrium{
  color: white;
  text-transform: uppercase;
  font-size: 25px;
  height: 60px;
}
.atrium_child2{
  position: relative;
  top: 20px;
  font-size: 21px;
}

#nav-a {
  text-decoration: none;
}
#nav-a:hover {
  color: #555;
}
#nav-page {
  background-image: none;
}
#nav-page-story {
  z-index: 12;
}
.checkbtn {
  font-size: 30px;
  color: white;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
  position: relative;
  z-index: 10;
}
#check {
  display: none;
}
#nav-page-utils {
  z-index: 999;
}
#nav-page-road {
  position: absolute;
  top: 0;
  margin: auto;
}
#homenav {
  position: fixed;
  top: 0;
  z-index: 1;
  /* width: 50%;
  margin-left: calc(100vw * 0.5); */
}
/* Navbar */
/* .terms-content {
  color: #fff;
  font-family: "Baumans", cursive;
  padding: 100px 50px;
  text-align: justify;
  background-color: #040610;
} */
.terms-content h1 {
  color: #00b894;
}

/* FAQ PAGE */
.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  position: relative;
  /* top: 150px; */
  padding: 15px;
  font-family: "Baumans", cursive;
  /* background-color: #040610; */
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  /* border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
  color: #63ffe5;
}
.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  color: #fff;
  transition: all 0.4s ease-out;
  white-space: pre-line;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
#fh1 {
  text-align: center;
  color: #fff;
  font-size: 62px;
}
.f-underline {
  width: 90px;
  border: 2px solid #00ffbe;
  margin: auto;
}
/* FAQ PAGE */

/* Media Queries */
@media (max-width: 952px) {
  nav ul li a {
    font-size: 22px;
  }
  .atrium{
    font-size: 22px;
  }
  .header-content p {
    font-size: 22px;
  }
  .icon {
    width: 100px;
  }
  .icon p {
    font-size: 12px;
  }
}
@media (max-width: 858px) {
  .checkbtn {
    display: block;
  }
  ul {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all 0.5s;
    z-index: 1;
    background-color: #000;
  }
  nav ul li {
    display: block;
    margin: 50px 0;
    line-height: 30px;
  }
  nav ul li a {
    font-size: 18px;
  }
  .atrium{
    font-size: 18px;
  }

  /* #check:checked ~ ul {
    left: 0;
  } */
  /* .header-content p {
    font-size: 18px;
    padding: 0px 50px;
    padding-bottom: 70px;
    margin-top: 28px;
  } */
  .icon {
    width: 100px;
  }
  .icon {
    width: 60px;
    height: 60px;
  }
  .icon p {
    display: none;
  }
  .icon i {
    margin-top: -10px;
    font-size: 24px;
  }

  .story-image-container {
    width: 100%;
  }
  #uh1 {
    font-size: 48px;
  }
  .section-team .single-person .person-image .team-icons i {
    margin-top: 15px;
    margin-left: 14px;
    margin-right: 15px;
  }
}
@media (max-width: 650px) {
  .header-content p {
    font-size: 15px;
  }
}
/* SLIDER */
.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.slider--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.slider--feature {
  text-align: center;
}

.feature--title {
  font-size: 2.5rem;
  color: #fff;
  font-family: "Baumans", cursive;
  text-transform: uppercase;
  font-weight: 700;
}

.feature--text {
  font-size: 14px;
  color: #fff;
}

.slider__btn-right,
.slider__btn-left {
  background: transparent;
  border: none;
  outline: none;
  font-size: 4rem;
  color: #eee;
  padding: 0 1rem;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.feature__btn {
  background: #fff;
  text-transform: uppercase;
  border: none;
  color: #444;
  border: 1px solid #444;
  outline: none;
  font-weight: 700;
  padding: 0.8rem 2rem;
  cursor: pointer;
}

.slider__btn-left:hover,
.slider__btn-right:hover {
  transform: scale(0.95);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 0.5s;
}

/*ROADMAP  */
::selection {
  color: #fff;
}
.wrapper {
  font-family: "Baumans", cursive;
  max-width: 1080px;
  color: #fff;
  margin: 50px auto;
  padding: 0 20px;
  position: relative;
}
.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #00ffbe;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.wrapper .row {
  display: flex;
}
.wrapper .row-1 {
  justify-content: flex-start;
}
.wrapper .row-2 {
  justify-content: flex-end;
}
.wrapper .row section {
  background: transparent;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  border: 1px solid #00ffbe;
  position: relative;
}
.wrapper .row section::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #00ffbe;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}
.row-1 section::before {
  right: -7px;
}
.row-2 section::before {
  left: -7px;
}
.row section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: #f2f2f2;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #00ffbe;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.row-1 section .icon {
  top: 15px;
  right: -60px;
}
.row-2 section .icon {
  top: 15px;
  left: -60px;
}
.row section .details,
.row section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row section .details .title {
  font-size: 22px;
  font-weight: 600;
}
.row section p {
  margin: 10px 0 17px 0;
}
.row section .bottom a {
  text-decoration: none;
  background: #3ea0e2;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  /* font-size: 17px; */
  font-weight: 400;
  transition: all 0.3s ease;
}
.row section .bottom a:hover {
  transform: scale(0.97);
}
@media (max-width: 790px) {
  .wrapper .center-line {
    left: 40px;
  }
  .wrapper .row {
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section {
    width: 100%;
  }
  .row-1 section::before {
    left: -7px;
  }
  .row-1 section .icon {
    left: -60px;
  }
}
@media (max-width: 440px) {
  .wrapper .center-line,
  .row section::before,
  .row section .icon {
    display: none;
  }
  .wrapper .row {
    margin: 10px 0;
  }
}

/* SLIDER REACT*/
.awssld__container figure,
.awssld__content,
.awssld__box {
  display: block;
  /* display: flex;
  flex-direction: column;
  justify-content: center !important; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.awssld__controls button,
.awssld__bullets button {
  outline-color: 0;
  outline-style: none;
  display: none;
  outline-width: 0;
}

.awssld {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 60%;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #6a6a6a;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --content-background-color: #000;
}

.awssld {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
}
.awssld__wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}
.awssld__container {
  display: block;
  width: 100%;
  height: 100vh;
  padding-bottom: var(--slider-height-percentage);
}
@media all and (max-width: 500px) {
  .awssld__container {
    padding-bottom: calc(var(--slider-height-percentage) * 1.25);
  }
}
.awssld__startUp {
  background-color: red;
  height: 100%;
  width: 100%;
}
.awssld__startUp > div {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.awssld__startUp img {
  width: 35%;
  height: auto;
}
.awssld__content {
  background-color: var(--content-background-color);
  overflow-y: scroll;
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.awssld__content > img,
.awssld__content > video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.awssld__controls button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: var(--control-button-width);
  height: var(--control-button-height);
  position: absolute;
  z-index: 10;
  top: calc(50% - (0.5 * var(--control-button-height)));
  border: none;
  background-color: var(--control-button-background);
  color: #fff;
  cursor: pointer;
}

.awssld__controls--hidden {
  display: none;
}
@media all and (max-width: 520px) {
  .terms-content {
    padding: 100px 50px;
  }
  .terms-content h1{
    font-size: 23px;
  }

  .awssld__controls {
    visibility: hidden;
  }
}
.awssld__bar {
  display: block;
  width: 100%;
  height: var(--loader-bar-height);
  background-color: var(--loader-bar-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  -webkit-transition: -webkit-transform 3000ms var(--transition-bezier);
  transition: -webkit-transform 6000ms var(--transition-bezier);
  transition: transform 6000ms var(--transition-bezier);
  transition: transform 6000ms var(--transition-bezier),
    -webkit-transform 3000ms var(--transition-bezier);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.awssld__bar--active {
  -webkit-transform: translate3d(-20%, 0, 0);
  transform: translate3d(-20%, 0, 0);
}
.awssld__bar--end {
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.awssld__next {
  right: 0;
}
.awssld__prev {
  left: 0;
}
.awssld__box {
  z-index: 1;
  visibility: hidden;
}
.awssld--animated {
  will-change: transform;
  visibility: visible;
}
.awssld--animated-mobile {
  will-change: transform;
  -webkit-transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
  transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
  transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
  transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1),
    -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
}
.awssld--active {
  visibility: visible;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.awssld--moveRight,
.awssld--moveLeft {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.awssld--moveRight {
  -webkit-animation: slideFromRight var(--slider-transition-duration) both
    var(--transition-bezier);
  animation: slideFromRight var(--slider-transition-duration) both
    var(--transition-bezier);
}
.awssld--moveLeft {
  -webkit-animation: slideFromLeft var(--slider-transition-duration) both
    var(--transition-bezier);
  animation: slideFromLeft var(--slider-transition-duration) both
    var(--transition-bezier);
}
.awssld--exit {
  z-index: 0;
}
.awssld--exit.awssld--moveLeft {
  -webkit-animation: slideToLeft var(--slider-transition-duration) both
    var(--transition-bezier);
  animation: slideToLeft var(--slider-transition-duration) both
    var(--transition-bezier);
}
.awssld--exit.awssld--moveRight {
  -webkit-animation: slideToRight var(--slider-transition-duration) both
    var(--transition-bezier);
  animation: slideToRight var(--slider-transition-duration) both
    var(--transition-bezier);
}
.awssld--first .awssld__prev {
  visibility: hidden;
}
.awssld--last .awssld__next {
  visibility: hidden;
}
.awssld--fill-parent {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
}
.awssld--fill-parent .awssld__container {
  height: 100%;
  padding: 0;
}
.awssld__bullets {
  display: none;
}
.awssld__bullets button {
  padding: 0;
  display: hidden;
  width: 16px;
  height: 16px;
  margin: 5px;
  border-radius: 50%;
  background: var(--control-bullet-color);
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  -webkit-transition: background-color 0.175s ease-out,
    -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
  transition: background-color 0.175s ease-out,
    -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45),
    background-color 0.175s ease-out;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45),
    background-color 0.175s ease-out,
    -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
}
.awssld__bullets button:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.awssld__bullets .awssld__bullets--loading {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.awssld__bullets .awssld__bullets--active {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  background: var(--control-bullet-active-color);
}
.awssld__bullets .awssld__bullets--active:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.awssld__timer {
  --timer-delay: 2000ms;
  --timer-release: 200ms;
  --timer-height: 4px;
  --timer-background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: var(--timer-height);
  background-color: var(--timer-background-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.awssld__timer--animated {
  will-change: transform;
  -webkit-transition: -webkit-transform var(--timer-delay) linear;
  transition: -webkit-transform var(--timer-delay) linear;
  transition: transform var(--timer-delay) linear;
  transition: transform var(--timer-delay) linear,
    -webkit-transform var(--timer-delay) linear;
}
.awssld__timer--run {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.awssld__timer--fast {
  -webkit-transition: -webkit-transform calc(var(--timer-release) / 2) linear;
  transition: -webkit-transform calc(var(--timer-release) / 2) linear;
  transition: transform calc(var(--timer-release) / 2) linear;
  transition: transform calc(var(--timer-release) / 2) linear,
    -webkit-transform calc(var(--timer-release) / 2) linear;
  -webkit-transform: translateX(-0.00001px);
  transform: translateX(-0.00001px);
}
.awssld__timer--end {
  -webkit-transform: translateX(-0.0001px);
  transform: translateX(-0.0001px);
  -webkit-transition: -webkit-transform var(--timer-release) linear;
  transition: -webkit-transform var(--timer-release) linear;
  transition: transform var(--timer-release) linear;
  transition: transform var(--timer-release) linear,
    -webkit-transform var(--timer-release) linear;
}
.awssld__timer--hidden {
  display: none;
}

@-webkit-keyframes slideFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideFromRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideFromRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideToLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideToLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes slideToRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideToRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 500px) {
  /* Center the Modal! */
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 30em;
    max-height: calc(100% - 1em);
  }
}
@media screen and (max-width: 858px) {
  #homenav {
    width: 50%;
    margin-left: calc(100vw * 0.5);
    background: transparent;
  }
}
@media screen and (max-width: 500px) {
  #homenav {
    margin-left: calc(100vw * 0.53);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

@media screen and (max-width: 2880px) {

  nav {
    height: 120px;
  }
  nav ul li {
    display: inline-block;
    line-height: 120px;
    margin: 0 5px;
  }
  nav ul li a {
    font-size: 42px;
    /* margin-right: 40px; */
  }
  .atrium{
    font-size: 42px;
    /* margin-right: 50px; */
  }

}
@media screen and (max-width: 2000px) {
  nav {
    display: inline-block;
    height: 60px;
  }
  .atrium_child2{
    top: 13px;
    font-size: 13px;
  }
  nav ul li {
    display: inline-block;
    line-height: 50px;
    margin: 0 5px;
  }
  nav ul li a {
    font-size: 24px;
  }
  .atrium{
    font-size: 24px;
  }
}
@media screen and (max-width: 1680px) {
  nav {
    display: inline-block;
    height: 80px;
  }
  nav ul li {
    display: inline-block;
    /* line-height: 50px;nav ul li a */
    /* margin: 0 5px; */
  }
  nav ul li a {
    font-size: 26px;
  }
  .atrium{
    font-size: 26px;
  }
}
@media screen and (max-width: 858px) {
  .atrium{
    font-size: 20px;
  }
  .atrium_child2{
    font-size: 10px;
    top: -33px;
  }
}
