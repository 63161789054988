.page {
  background-image: url(../assets/Desktop\ Background.png);
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.pageBody {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 15%;
  width: 400px;
  margin-left: -5%;
}

.elements {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.plus {
  width: 80px;
  height: 80px;
}

.minus {
  width: 80px;
  height: 80px;
}

.number {
  width: 60px;
  height: 60px;
  margin-top: 1rem;
}

.mintBtn {
  width: 250px;
  height: 150px;
  margin-left: 5rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 1600px) {
  .pageBody {
    margin-top: 8%;
  }
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1100px) {
}

@media only screen and (max-width: 600px) {
  .page {
    background-image: url(../assets/Phone\ background.png);
  }
}
